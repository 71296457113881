












































































































































import { SfImage } from '@storefront-ui/vue';
import SfButton from '~/components/StorefrontUi/SfButton.vue';
import {
  defineComponent, ref, useFetch,
} from '@nuxtjs/composition-api';
import { useUiHelpers, useImage } from '~/composables';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import { addBasePath } from '~/helpers/addBasePath';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { SortEnum } from '~/modules/GraphQL/types';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import ImageWrapper from '~/components/image/ImageWrapper.vue';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'CategoriesTilesSection',
  components: {
    SfButton,
    SfImage,
    SkeletonLoader,
    ImageWrapper,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { addTags } = useCache();
    const { search, result, loading } = useCategorySearch();
    const { getCatLink } = useUiHelpers();
    const orderedListOfCategories = ['366', '367', '368', '369', '370', '371', '372', '373', '374', '377', '380', '539', '540', '391', '393', '533', '388', '427', '392', '394', '397'];
    const { isAuthenticated } = useUser();

    useFetch(async () => {
      await search({
        pageSize: 21,
        filters: {
          ids: {
            in: orderedListOfCategories,
          },
        },
        sort: {
          mainsite_priority: SortEnum.Asc,
        },
      });

      if (!isAuthenticated.value) {
        const categoriesTags = result.value?.map((category) => ({
          prefix: CacheTagPrefix.Category,
          value: category.uid,
        }));

        addTags(categoriesTags);
      }
    });

    const isToggleMobileMenu = ref(true);
    const toggleMobileMenu = () => {
      isToggleMobileMenu.value = !isToggleMobileMenu.value;
    };
    // TODO: Sort in different way. This sorting method causes 'computed ref is readonly' error.
    // const searchedCategories = computed(() => {
    //   const resultCopy = result.value;
    //   return resultCopy
    //   // @ts-ignore
    //     ? resultCopy.sort(
    //       (a, b) => orderedListOfCategories.indexOf(a.id.toString() as string) - orderedListOfCategories.indexOf(b.id.toString() as string),
    //     )
    //     : {};
    // });

    const { getMagentoImage } = useImage();

    return {
      searchedCategories: result || {},
      getCatLink,
      productGetters,
      addBasePath,
      searchedCategoriesLoading: loading,
      getCategoryImage,
      isToggleMobileMenu,
      toggleMobileMenu,
      getMagentoImage,
    };
  },
});
